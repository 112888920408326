import React, {useEffect, useContext} from "react"
import Seo from "../../components/utilities/Seo"
import ContactSection from '../../components/components/ContactSection'

import {motion, useAnimation , usePresence} from "framer-motion"
import {SectionNavContext} from '../../components/utilities/SiteContexts'
import {contactHolder} from '../pages.module.scss'

const pageVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.8
    }
  }
}


const AboutContactPage = ({isDark}) => {
  const [, setSectionNav] = useContext(SectionNavContext)

  const pageControl = useAnimation()
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    pageControl.start('vis')
  }, [pageControl])

  useEffect( ()=>{

    const exitAnimation = async () => {
      await pageControl.start('post')
      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  },[pageControl, isPresent, safeToRemove])

  useEffect(() => {
    setSectionNav([
      {
        to: '/',
        title: 'Home'
      },
      {
        to: '/about/video',
        title: 'Video'
      },
      { 
        to: '/about/benefits',
        title: 'Benefits'
      },
      {
        to: '/about/expertise',
        title: 'Expertise',
      },
      {
        to: '/about/achievements',
        title: 'Achievements'
      },
      {
        to: '/about/contact',
        title: 'Contact',
        active: true
      }
    ])
  }, [setSectionNav])

  return (
    <motion.article className={contactHolder} variants={pageVariants} animate={pageControl} initial="pre">
      <Seo title="Contact" description="Contact Carbon Climate Solutions" />
      <ContactSection title={"Contact us"} isDark={isDark} />
    </motion.article>
  )
}

export default AboutContactPage
